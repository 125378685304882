

import React from 'react'
import SeoPage from '../components/SeoPage'
import UpperForm from '../components/UpperForm'
import BottomForm from '../components/BottomForm'
import { StaticImage } from 'gatsby-plugin-image'
import LeadImage from '../images/avocat-modification-annulation-droit-garde-pension-alimentaire-montreal.jpeg'
import { graphql } from 'gatsby'
import ConversionLink from '../components/ConversionLink'


const BlogPost30 = ({ data, location, ...props }) => (
    <SeoPage location={location}
        title="Modification de garde et pension alimentaire : quel prix pour un avocat en droit de la famille à Montréal? - Soumissions Avocat"
        description="Vous croyez payer injustement une pension alimentaire trop élevée? Vous désirez un arrangement plus équitable entre vous et votre ex-conjoint? Demandez l’aide d’un avocat en droit de la famille à Montréal!"
        image={LeadImage}>
        <h1>Modification de garde et pension alimentaire : quel prix pour un avocat en droit de la famille à Montréal?</h1>

					
					
						<p>La pension alimentaire est un mécanisme légal qui protège les intérêts et le bien-être des enfants lors d’une séparation. Elle est d’ailleurs au cœur de toutes les demandes en divorce dans le but de préserver l’élément phare du processus, soit l’intérêt de l’enfant. C’est donc avec toutes les considérations humaines et financières nécessaires qu’elle est mise en place au juste montant.</p>
<p><StaticImage alt="Modification garde et pension alimentaire Montréal" src="../images/avocat-modification-annulation-droit-garde-pension-alimentaire-montreal.jpeg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p><strong>Toutefois, qu’arrive-t-il lorsqu’une inégalité survient entre les parents quant à la somme versée?</strong> C’est à ce moment précis qu’il devient pertinent de réévaluer la situation pour demander la modification ou même l’annulation de la pension. Comment parvenir à cette fin? Vous demandez l’aide des meilleurs avocats familialistes à Montréal!</p>
<p><strong>Soumissions Avocat vous mettra rapidement en contact avec eux, il suffit de remplir le formulaire!</strong></p>
<h1><strong>Qu’est-ce que la pension alimentaire et à qui est-elle due?</strong></h1>
<p><strong>Qu’est-ce que le droit aux aliments?</strong> Le Code civil du Québec prévoit un concept très simple appelé « le droit aux aliments ». Ce droit ne se limite pas à la nourriture, mais comprend tous les besoins physiques et moraux propres à l’enfant auxquels ses parents sont dans l’obligation de répondre.</p>
<p>Lorsque les parents de l’enfant forment toujours un couple, ce besoin est comblé simplement et naturellement dans la vie de famille quotidienne. Cependant, une séparation vient chambouler cette symbiose et nécessite une correction sous forme de pension alimentaire!</p>
<p>En effet, la pension pour enfants cherche à maintenir la survenance aux besoins de l’enfant malgré la séparation en créant un équilibre dans chacun de ses deux milieux de vie, soit chez chacun de ses parents. Ainsi, c’est souvent le parent à l’état financier plus prospère qui sera tenu au versement de la pension pour éviter un trop grand écart.</p>
<p><strong>Par souci de clarté, il est important de distinguer entre la pension alimentaire pour enfants et celle pour ex-conjoints!</strong></p>
<p><strong>La pension alimentaire pour enfants :</strong> Ce type de pension alimentaire est due dès lors qu’il y a une séparation engendrant un déséquilibre soit entre les situations financières des parents ou dans le temps de garde. Dans un cas comme dans l’autre, c’est la pension alimentaire qui rétablira l’équilibre convoité.</p>
<p>D’ailleurs, le mécanisme de cette pension s’opère que le couple soit marié ou simplement conjoints de fait.</p>
<p><strong>La pension alimentaire pour ex-conjoints :</strong> À contrario de la pension pour enfants, celle pour ex-conjoints n’est due que dans le cadre d’un divorce, impliquant ainsi que le couple était marié. Des facteurs tels que la durée du mariage et l’apport de chacun seront considérés, mais c’est surtout l’aspect financier qui primera.</p>
<p>Comme dans le cas de la pension pour enfants, celle pour ex-conjoints vise à éviter que l’un ou l’autre des époux ne se retrouve dans une situation financière nettement inférieure à celle connue lors du mariage.</p>
<p><strong>Quelle pension prime lorsque les deux sont dues? </strong>Dans une situation de divorce impliquant des enfants, il se peut que l’un des époux ait également droit à une pension. S’il est possible que chacune d’elles soit ordonnée, celle pour enfants primera toujours sur celle pour l’ex-conjoint en cas de moyens financiers limités.</p>
<p><strong>Vous croyez être en droit de demander une ou l’autre des ces pensions alimentaires? Les avocats en droit de la famille à Montréal se spécialisent dans ce domaine et sauront vous obtenir une pension juste et équitable.</strong></p>
<h2>Quels sont les critères pour modifier un jugement de pension alimentaire?</h2>
<p>Tout paiement de pension alimentaire commence avec une ordonnance alimentaire établie suite à la séparation du couple. Il s’agit là d’un jugement que chacun des époux se doit de respecter et qui n’est pas simple à changer. En effet, la loi prévoit qu’une modification de pension alimentaire deviendra possible dans une situation de <strong>changement « significatif et imprévu ».</strong></p>
<p><StaticImage alt="Critères modification jugement pension alimentaire" src="../images/criteres-etablissement-pension-alimentaire-enfant-avocat-montreal.jpeg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p>Le droit étant ce qu’il est, il faut pousser l’analyse un peu plus loin pour comprendre le sens de cette expression! C’est en justifiant un changement dans les conditions de fixation de la pension alimentaire que le tribunal accordera une modification!</p>
<p><strong>Si vous vous trouvez dans l’une des situations suivantes, vos chances d’obtenir un jugement autorisant la modification de votre pension sont bonnes!</strong></p>
<p><strong>Modification au niveau du temps de garde :</strong> Toujours au centre de toute décision sur la pension alimentaire, le temps de garde a une incidence directe sur la situation financière du parent ayant la plus grande proportion de la garde. Il est donc normal qu’il soit le bénéficiaire de la pension, mais il est tout aussi justifié d’en demander l’ajustement lorsque le temps de garde change.</p>
<p>Effectivement, si vous passez d’un parent ayant simplement des droits d’accès les fins de semaine à un parent ayant la garde partagée, il est tout à fait normal de demander à ce que la pension soit ajustée en conséquence.</p>
<p>Vous paierez davantage de frais reliés à l’éducation et surveillance de vos enfants; il est donc normal que vous cessiez de payer à votre ex-conjoint qui les voit moins qu’en date du jugement.</p>
<p><strong>Changement de revenu disponible :</strong> Que votre situation financière se soit améliorée de façon significative ou que vous soyez près de la faillite, une révision de la pension peut être demandée. L’argent est un argument qui pèse lourd dans la balance de fixation des pensions et cela vise à éviter que l’un des ex-conjoints ne s’enrichisse aux dépens de l’autre.</p>
<p>C’est le revenu disponible (selon la définition légale et le calcul prévu) qui est pris en compte et non le salaire annuel. Il s’agit d’une distinction légale ayant un grand impact.</p>
<p><strong>Nombre d’enfants à charge :</strong> Si vous avez eu plusieurs enfants et que l’un d’entre eux devient autonome financièrement ou a maintenant un emploi à temps partiel, il devient tout à fait possible de demander une modification de pension et même une annulation complète.</p>
<p><strong>Déménagement :</strong> Le déménagement est un évènement typique qui se répercute surtout sur le temps de garde, mais également sur les obligations financières de chacun. S’il faut qu’un parent décide de déménager dans une ville éloignée, des frais seront presque inévitablement encourus par l’autre conjoint; il faudra donc rajuster la pension en conséquence.</p>
<p>Avec un avocat familialiste d’expérience, la modification d’un jugement de pension alimentaire se fait rapidement. Demandez l’aide des meilleurs avocats à Montréal!</p>
<h2>Quand est-ce possible d’annuler une pension alimentaire?</h2>
<p>Après la modification vient souvent la demande en annulation! En effet, comme la pension vise à préserver les besoins d’éducation et de surveillance des enfants, il va de soi qu’elle est destinée à prendre fin le jour de leur départ vers le monde adulte. <strong>Est-ce que cela implique qu’à 18 ans vous pouvez ranger votre carnet de chèques pour de bon? </strong>On est loin du compte! Voici les circonstances qui justifieront une modification de pension alimentaire!<strong>
</strong>
<strong>La mort de l’enfant :</strong> Bien que vous n’ayez pas la tête à cela lors d’un tel évènement, la loi prévoit qu’une telle circonstance justifiera effectivement l’annulation d’un jugement de pension.</p>
<p><strong>À la date indiquée dans le jugement :</strong> Les ordonnances de pension alimentaire stipulent généralement une date de prise de fin des versements. Lorsque ce moment vient à échéance, vous serez justifié de cesser à payer les montants de la pension alimentaire.</p>
<p><strong>Un jugement à cet effet :</strong> Si vous ne désirez pas attendre l’échéance du jugement, il est également possible de vous adresser à la cour avant de moment pour demander l’annulation de la pension. Vous devrez toutefois invoquer des arguments persuasifs que seul un avocat en droit de la famille saura faire valoir!</p>
<p><strong>Autonomie financière de l’enfant :</strong> Comme la pension cherche à répondre aux besoins de l’enfant, elle devient bien évidemment obsolète lorsqu’il est en mesure de répondre à ses propres besoins. La plupart du temps, lorsque les relations sont bonnes, l’enfant ne s’opposera pas à la demande en annulation. Toutefois, il conserve le droit de plaider sa cause et de prétendre avoir toujours besoin de ce montant périodique!</p>
<h2>Sur quelle base se décide la garde d’un enfant?</h2>
<p>Vous vous trouvez dans une situation de séparation récente et vous vous demandez comment se décidera la garde de vos enfants. Sachez qu’il est toujours possible de vous entendre avec votre ex-conjoint sur les modalités de la garde en faisant homologuer une entente relative à ces aspects. Cela sauvera du temps et des maux de tête à toutes les personnes impliquées, croyez-nous!</p>
<p><StaticImage alt="Critères pour décider garde d’enfants" src="../images/comment-decider-modifier-garde-enfants-divorce-avocat-montreal.jpeg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p>Il se peut toutefois que les circonstances envenimées ne permettent pas pareil dénouement, en quel cas il faudra passer devant le juge pour que ce dernier décide à votre place. Au moment d’attribuer la garde, ce sont les aspects suivants qui seront pris en compte :</p>
<p><strong>Les besoins physiques et émotionnels :</strong> Une analyse effectuée par divers intervenants, lorsque nécessaire, sera ordonnée par le tribunal afin de déterminer si l’enfant a des besoins particuliers qui justifient d’ordonner un type de garde plus qu’un autre. Le juge va toujours considérer tous les besoins de l’enfant avant de rendre son jugement, car c’est son bien-être qui prime sur celui de toute personne impliquée.</p>
<p><strong>L’état des relations parents-enfants :</strong> Sans donner un poids trop important aux caprices qu’un enfant pourrait avoir, le juge sera particulièrement sensible à l’état des relations entre parents et enfants au moment de rendre ce jugement.</p>
<p>C’est en se fiant au critère de « l’intérêt de l’enfant » qu’il pourra décider lequel des deux parents devrait recevoir la garde exclusive, la plus grande portion de la garde partagée ou même si une garde équitable s’impose.</p>
<p><strong>La présence de  frères et sœurs :</strong> En tenant compte du principe voulant qu’on ne sépare pas la fratrie, le juge fera tout pour garder les enfants ensemble et il se peut que la réalité de chacun des parents fasse pencher la balance vers une garde plus importante chez l’un des parents que l’autre.</p>
<p><strong>La volonté des enfants (selon l’âge) :</strong> Le droit de la famille confère de plus en plus de ponds à la volonté de l’enfant en fonction de son niveau de maturité et de son pouvoir de discernement. L’âge n’est qu’un indicateur de maturité, bien que jeunes enfants puissent être en mesure de formuler une opinion éclairée. Ce sera au juge de déterminer pareille présence d’esprit chez l’enfant et d’ensuite décider le poids accordé à cette volonté.</p>
<p><strong>La capacité des parents :</strong> La volonté de chaque parent est loin d’importer autant que leur capacité à prendre soin de l’enfant. Si l’un d’entre eux démontre une capacité nettement supérieure à s’occuper de sa progéniture, le jugement sera rendu en conséquence.<strong>
</strong>
<strong>Les critères qui n’ont pas d’importance aux yeux d’un juge!</strong> Avant de monter sur vos grands chevaux en pensant avoir ce qu’il faut pour obtenir la garde que vous désirez, prenez le temps de vérifier la validité de vos arguments.</p>
<p>Notamment, les juges demeurent indifférents à un bon nombre de critères, dont la survenance d’un adultère, d’un nouveau conjoint ou même d’un remariage. Ce qui intéresse le juge, c’est de déterminer quelle situation est la plus susceptible de répondre aux besoins de l’enfant.</p>
<h2>Pouvez-vous récupérer la garde de vos enfants après l’avoir perdue?</h2>
<p>La principale raison pour qu’un parent se fasse retirer la garde de son enfant est le recours en déchéance de l’autorité parentale. Il s’agit d’une façon de retirer les droits d’éducation, de surveillance et de garde à un parent envers son enfant tout en maintenant en vie l’obligation alimentaire. Des motifs tels que l’abandon, la violence et les abus justifient le recours en déchéance.</p>
<p><strong>Il est toutefois possible qu’un parent récupère son autorité et sa garde! </strong>Se faire restituer de son autorité parentale est la première étape et elle doit être justifiée en vertu d’un changement de situation personnelle important. Notamment si des problèmes de consommation ou psychologiques étaient dans le portrait, ceux-ci devront avoir été réglés avant de considérer le rétablissement de l’autorité.</p>
<p>Il va également falloir établir la capacité du parent à prendre en charge les responsabilités d’éducation de l’enfant par l’entremise de plusieurs évaluations psychosociales.</p>
<p>Dans tous les cas, un tel rétablissement ne sera pas possible si un juge détermine que les besoins de l’enfant ne vont pas dans ce sens et que la situation de déchéance est dans son meilleur intérêt.</p>
<p><strong>En définitive, il est possible de récupérer la garde même après avoir été dépouillé de ses droits en tant que parent. Les secondes chances existent et il suffit d’engager un avocat pour en bénéficier!</strong></p>
<h2>Les mythes et réalités de la pension alimentaire pour enfant</h2>
<p>Étant donné le piètre taux de succès des mariages au Québec, il n’est pas étonnant que les demandes de droit de garde et de pension alimentaires se présentent par milliers devant les tribunaux. Une telle présence de ces dossiers dans l’imaginaire collectif implique également la propagation de légendes urbaines sur le sujet. Pour contrer ce phénomène, nous démystifions le tout pour vous.</p>
<p><StaticImage alt="Les mythes entourant la garde des enfants" src="../images/mythes-realites-pension-garde-enfants-droit-famille-montreal.jpeg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<h4><strong>
Rendu à 18 ans, mon enfant n’a plus le droit à une pension alimentaire</strong></h4>
<p><strong>Faux</strong>! La majorité de votre enfant n’est en aucun cas synonyme de cessation de votre pension. Vous êtes tenu de verser une pension alimentaire à votre progéniture jusqu’à ce que celle-ci soit en mesure de subvenir à ses besoins financiers.</p>
<p>Cela implique que vos enfants devront avoir trouvé un travail et même terminé leurs études dans certains cas pour que vous puissiez demander une annulation de pension. Une modification pourrait toutefois être envisageable si votre enfant occupe un emploi à temps partiel.</p>
<p>Si toutefois votre enfant est rendu à la mi-vingtaine et qu’il ne démontre aucune intention sérieuse de voler de ses propres ailes, il s’agit là d’une situation différente qui pourrait justifier de cesser lesdits paiements.</p>
<h4>Je peux convaincre mon ex-conjoint de renoncer à son droit à la pension par contrat</h4>
<p>Mythe! Le droit à une pension alimentaire (autant pour enfant que pour ex-conjoint) est une notion légale d’ordre public, ce qui signifie qu’aucun contrat ne peut prévoir la renonciation de ces droits. Concrètement, cela signifie que même si vous faites signer un contrat à votre ex-conjoint stipulant qu’il ou elle s’engage à ne pas vous demander de versement de pension alimentaire, vous gaspilleriez du papier et de l’encre en vain.</p>
<p>Cela est d’autant plus vrai en ce qui concerne la pension pour enfants! Celle-ci est faite à l’ex-conjoint au bénéfice des enfants. Il est donc normal que ce dernier ne puisse pas la refuser.</p>
<h4>Si je n’ai plus les moyens, je peux cesser de payer une pension alimentaire</h4>
<p>Vrai et faux… Cette allégation se doit d’être nuancée adéquatement. Ce n’est pas parce que vous vous trouvez dans une situation financière précaire que vous pourrez vous dégager de l’obligation de payer une pension. Cependant, de telles difficultés peuvent justifier un rééquilibre des montants versés.</p>
<p>Par ailleurs, si deux pensions vous sont exigées, il se peut qu’on vous permette de privilégier celle faite au bénéfice de vos enfants avant celle versée à votre ex-conjoint(e).</p>
<h4>Je n’ai pas la garde de mes enfants ni aucun droit d’accès, alors je ne dois aucune pension.</h4>
<p><strong>Faux</strong>! Il s’agit là d’une raison de plus pour vous forcer à verser une pension alimentaire! Même les parents déchus de leur autorité parentale ne sont pas exonérés de leur obligation de payer des aliments à leurs enfants.</p>
<p>Cela signifie donc que même dans un cas où vous n’avez aucun contact avec vos enfants, vous serez toujours tenus de leur verser une pension. Le montant à défrayer risque d’être d’autant plus important en pareilles circonstances, d’ailleurs.</p>
<h2>Trouvez un avocat en droit de la famille à Montréal pour votre dossier de garde et pension alimentaire!</h2>
<p><strong>Vous trouvez que vous payez trop pour la pension alimentaire ou que votre ex-conjoint ne paie pas sa juste part?</strong> Vous n’avez pas à être la cible d’une telle injustice, d’autant plus que ce sont vos enfants qui en paient les frais. Heureusement pour vous, il existe une solution fort simple pour remédier à la situation et il s’agit d’engager un avocat en droit de la famille!</p>
<p><StaticImage alt="Engager avocat droit de la famille à Montréal" src="../images/engager-trouver-services-avocats-famille-pension-alimentaire-montreal.jpeg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p>Dans la région de Montréal, vous retrouverez les meilleurs avocats familialistes de la province qui parviendront à vous obtenir un paiement de pension juste et équitable qui répondre à vos besoins et à ceux de votre famille.</p>
<p><strong>Par où commencer pour entamer la démarche? Tout débute par le formulaire de demande!</strong></p>
<p><strong>En nous faisant parvenir les détails de votre dossier, nous serons en mesure d’acheminer votre demande aux meilleurs juristes de la métropole. Pourquoi attendre alors que la mise en contact avec un avocat est gratuite!</strong></p>
    </SeoPage>
)
export default BlogPost30
  